@import "../variables.module.scss";

.grittiestCard {
  @include card-space;

  button {
    @include button-space;
    @include button-submit;
  }

  button:hover {
    @include button-hover;
  }

  fieldset {
    @include fieldset-basic-structure;
    align-items: center;
  }

  p.grittiestPara {
    @include p-character-para-center;
  }

  .grittiestPara {
    @include character-para-md;
  }

  .decade,
  .grittiestArmy {
    padding: 0.25rem;
  }

  .decade {
    @include input-decade;
  }

  input.decade {
    font-size: x-small;
  }

  input[type="number"] {
    width: $space-md;
    @include input-number-and-text-format;
  }

  input[type="text"]::placeholder {
    color: $ea-white;
    @include input-number-and-text-format;
  }

  input[type="text"] {
    @include input-combo-number-text-format;
  }

  .grittiestArmy {
    width: 33%;
    font-size: x-small;
  }

  .submitBtn {
    @include button-submit;
  }
}
