@import "../variables.module.scss";

.donaldsCard {
  background-color: rgba(255, 255, 0, 0.1);
  @include card-space;

  button {
    @include button-space;
    @include button-submit;
  }

  Button.donaldCloseBtn {
    color: #ffdd42;
    @include button-x-custom-3;
    font-size: 4.75rem;
  }

  button:hover {
    @include button-hover;
  }

  .changeQuoteBtn {
    color: #ffdd42;
    font-size: 1.35rem;
    @include button-recall-api;
  }

  .quoteText {
    color: $ea-white;
    text-shadow: 0.05rem 0.05rem #ffdd42;
    font-family: $font-luckiest-guy;
    font-size: 2.5rem;
    padding: 0 $space-md;
  }
}
