@import "../variables.module.scss";

.lastCard {
  background-color: rgba(255, 0, 0, 0.2);
  @include card-space;

  button {
    @include button-space;
    @include button-submit;
  }

  button:hover {
    @include button-hover;
  }

  fieldset {
    border: $space-border-none-all;
    display: flex;
    justify-content: center;
  }

  p {
    @include paragraph-basic;
  }

  input[type="radio"] {
    cursor: pointer;
    width: 0.25rem;
    accent-color: $ea-red;
  }

  input[type="radio"]:hover {
    accent-color: $ea-red;
    @include icon-click-effect;
  }

  .fa-md {
    color: $ea-red;
    margin-right: 0.33rem;
    margin-top: 1.25rem;
  }

  .customIcons {
    display: inline-flex;
    position: absolute;
    top: -1rem;
    left: -0.49rem;
    z-index: -1;
  }

  label {
    display: inline-flex;
    position: relative;
    color: $ea-red;
    font-size: larger;
    text-shadow: $space-xsm $space-xsm black;
  }

  .bothPara {
    @include character-para-xsm;
  }

  .para1 {
    float: left;
    margin-right: 2.25rem;
  }

  .para2 {
    float: right;
  }

  .submitBtn {
    @include button-submit;
    margin-left: -7rem;
  }
}
