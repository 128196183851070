@import "../variables.module.scss";

.pokemonsCard {
  background-color: rgba(0, 0, 255, 0.2);
  @include card-space;
  height: 60vh;

  button {
    @include button-space;
    @include button-submit-input-inline;
    font-size: 3.25rem;
    color: #f8c947;
  }

  button:hover {
    @include button-hover;
  }

  input[type="text"] {
    color: white !important;
  }

  .autocompleteSelect {
    margin: 0 auto;
    width: 37%;
  }

  .gifMatchGif {
    width: 11rem;
    margin-top: -9.5rem;
    margin-left: -33rem;
  }

  .searchBtn {
    color: $ea-white;
  }

  Button.searchBtn {
    font-size: 1.5rem;
    @include button-search-submit-block-display;
  }

  h1 {
    text-shadow: 0.2rem 0.2rem black;
    @include text-uppercase;

    z-index: -1;
  }

  .pokemonImg {
    width: 7rem;
  }

  .nonGifImage {
    width: 10rem;
    margin-top: -6rem;
    margin-right: -27rem;
  }

  .row {
    margin-left: $space-sm;
    margin-bottom: -2rem;
  }

  .column {
    float: left;
    width: 25%;

    text-align: left;
  }

  .specDetails {
    color: #f8c947;
    font-size: small;
    letter-spacing: 0.05rem;
    text-shadow: 0.05rem 0.05rem black;
  }

  video {
    -webkit-box-reflect: below;

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    z-index: -1;
  }
}
