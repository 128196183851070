@import "../variables.module.scss";

.starWarsCard {
  width: 87% !important;
  height: 70%;
  overflow: hidden;

  background-color: rgba(0, 0, 0, 1);
  background-image: radial-gradient(
      white,
      rgba(255, 255, 255, 0.2) 0.125rem,
      transparent 0.625rem
    ),
    radial-gradient(
      white,
      rgba(255, 255, 255, 0.15) 0.063rem,
      transparent 0.313rem
    ),
    radial-gradient(
      white,
      rgba(255, 255, 255, 0.1) 0.125rem,
      transparent 0.625rem
    ),
    radial-gradient(
      rgba(255, 255, 255, 0.4),
      rgba(255, 255, 255, 0.1) 0.125rem,
      transparent 0.313rem
    );
  background-size: 34.375rem 34.375rem, 21.875rem 21.875rem, 15.625rem 15.625rem,
    9.375rem 9.375rem;
  background-position: 0 0, 2.5rem 3.75rem, 8.125rem 16.875rem, 4.375rem 6.25rem;
  @include card-space;

  .specsDiv {
    @include p-character-para-center;
  }

  button {
    @include button-space;
    @include button-submit-input-inline;
    color: #feda4a;
  }

  button:hover {
    @include button-hover;
  }

  .autocompleteSelect {
    margin: 0 auto;
    width: 37%;
  }

  Button.searchBtn {
    color: #feda4a;
    font-size: 1.5rem;
    @include button-search-submit-block-display;
  }

  input[type="text"] {
    color: $ea-white !important;
  }

  h1 {
    font-size: 325% !important;
    @include text-uppercase;
  }

  h1,
  h5 {
    width: 300%;
  }

  .specificationsText {
    width: 100%;
    color: $ea-white;
    text-shadow: 0.025rem 0.025rem black;
  }

  .fade {
    position: relative;
    min-height: 70vh;
    top: -4.375rem;
    background-image: linear-gradient(0deg, transparent, black 75%);

    background-image: radial-gradient(
        white,
        rgba(255, 255, 255, 0.2) 0.125rem,
        transparent 0.625rem
      ),
      radial-gradient(
        white,
        rgba(255, 255, 255, 0.15) 0.063rem,
        transparent 0.313rem
      ),
      radial-gradient(
        white,
        rgba(255, 255, 255, 0.1) 0.125rem,
        transparent 0.625rem
      ),
      radial-gradient(
        rgba(255, 255, 255, 0.4),
        rgba(255, 255, 255, 0.1) 0.125rem,
        transparent 0.313rem
      );
    background-size: 34.375rem 34.375rem, 21.875rem 21.875rem,
      15.625rem 15.625rem, 9.375rem 9.375rem;
    background-position: 0 0, 2.5rem 3.75rem, 8.125rem 16.875rem,
      4.375rem 6.25rem;

    z-index: -1;
  }

  .star-wars {
    display: flex;
    justify-content: flex-end;
    width: 100vw;
    position: relative;
    height: 131.25rem;
    color: #feda4a;
    font-family: $font-pathway-gothic-one, sans-serif;
    font-size: 900%;
    font-weight: 500;
    letter-spacing: 0.438rem;
    line-height: 87%;
    perspective: 37.5rem;
    text-align: justify;
  }

  .crawl {
    margin-left: -60rem;
    position: relative;
    transform-origin: 40% 100%;
    animation: crawl 17s linear;
  }

  @keyframes crawl {
    0% {
      top: 0;
      transform: rotateX(20deg) translateZ(0);
    }
    100% {
      top: -393.75rem;
      transform: rotateX(25deg) translateZ(-156.25rem);
    }
  }
}
