@import "../variables.module.scss";

.epicCard {
  background-color: #f6f6e9;
  @include card-space;

  button {
    @include button-space;
    @include button-submit;
  }

  button:hover {
    @include button-hover;
  }

  img {
    margin-left: -1rem;
    margin-bottom: -0.75rem;
  }
}
