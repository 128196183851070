@import "../variables.module.scss";

.agifyCard {
  @include card-space;
  height: 67vh;

  button {
    @include button-space;
    @include button-submit;
  }

  button.closeBtn {
    font-size: 4.5rem;
  }

  Button {
    font-size: 1.5rem;
    margin-bottom: $space-sm;
  }

  button:hover {
    @include button-hover;
  }

  Button.searchBtn {
    @include button-search-submit-block-display;
  }

  input {
    @include input-basic-format;
    outline: none;
  }

  input[type="text"]::placeholder {
    @include input-text-placeholder;
    color: $ea-white;
    @include input-number-and-text-format;
  }

  input[type="text"] {
    color: $ea-white;
    border-bottom: 0.1rem solid $ea-white;
    font-size: medium;
  }

  input[type="text"]::selection {
    @include input-text-selection;
  }

  h1 {
    margin-top: 3rem;
    @include text-uppercase;
  }

  h2.errorText {
    font-size: x-large;
  }

  .unfoundName {
    font-size: 3rem;
    text-shadow: $space-xsm $space-xsm $ea-black;
  }

  .noEntryText {
    color: $ea-gray-light;
    letter-spacing: 0.08rem;
  }

  .nameSearchCount {
    font-size: medium;
    margin-top: -13.5rem;
  }

  summary.disclaimerSummary {
    font-size: xx-small;
    color: $ea-red;
    text-shadow: $ea-black 0.025rem 0.025rem;
    cursor: pointer;
  }

  details {
    margin-top: -2.5rem;
    margin-right: -20rem;
  }

  .animate {
    background-image: linear-gradient(
      -225deg,
      $ea-white 0%,
      #fff800 29%,
      $ea-red 67%,
      $ea-green 100%
    );
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: $ea-white;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 2s linear infinite;
    display: inline-block;
  }

  @keyframes textclip {
    to {
      background-position: 200% center;
    }
  }

  .nameAgeDiv {
    margin-bottom: 11rem;
  }

  p {
    margin-top: -$space-lg;
    font-size: 11rem;
    font-family: $font-abril-fatface;
  }

  .agePara {
    margin-bottom: -9rem;
    text-shadow: 0.25rem 0.25rem black;
  }

  .disclaimerPara {
    color: $ea-black;
    width: 11.5rem;
    font-size: x-small;
    text-align: justify;
    font-family: Arial, Helvetica, sans-serif;

    position: absolute;
    left: 1.75rem;
    top: 7rem;

    background-color: rgba(255, 0, 0, 0.7);
    padding: $space-sm;
  }
}
