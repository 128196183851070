@import "../variables.module.scss";

.badassestCard {
  background-color: rgba(188, 143, 143, 0.3);
  @include card-space;

  button {
    @include button-space;
    @include button-submit;
  }

  button:hover {
    @include button-hover;
  }

  fieldset {
    @include fieldset-basic-structure;
    align-items: center;
  }

  input[type="radio"] {
    width: 5%;
    height: 0.95rem;
    accent-color: lightyellow;
    cursor: pointer;
  }

  label {
    color: red;
    cursor: pointer;
  }

  p.badassestPara {
    @include p-character-para-center;
  }

  .badassestPara {
    @include character-para-md;
  }

  .characters {
    @include character-subheads;
  }

  span.characters {
    color: lightyellow;
  }

  .characters:hover {
    @include characters-red-text-hover;
  }

  .submitBtn {
    @include button-submit;
  }
}
