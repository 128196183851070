@import "../variables.module.scss";

.apiCard {
  @include card-space;

  button {
    @include button-space;
    @include button-submit;
  }

  button:hover {
    @include button-hover;
  }

  img {
    margin-left: -1rem;
    margin-bottom: -0.85rem;
  }
}
