@import "../variables.module.scss";

.chucksCard {
  background-color: rgba(255, 165, 0, 0.2);
  @include card-space;

  button {
    @include button-space;
    @include button-submit;
  }

  Button.chuckCloseBtn {
    color: orangered;
    @include button-x-custom-2;
  }

  button:hover {
    @include button-hover;
  }

  .changeJokeBtn {
    color: orangered;
    font-size: 1.5rem;
    @include button-recall-api;
  }

  .jokeText {
    color: $ea-white;
    text-shadow: 0.05rem 0.05rem orangered;
    font-family: $font-bangers;
    font-size: $space-md;
    padding: 0 $space-md;
  }
}
