@import "../variables.module.scss";

.dangerousCard {
  background-color: $ea-filler-black-background;
  padding: 0;
  margin: 0;
  @include card-space;

  button {
    @include button-space;
    @include button-submit;
    margin-bottom: 3rem;
  }

  button:hover {
    @include button-hover;
  }

  section {
    @include section-centered;
  }

  p {
    @include beaut-dang-para-styles;
  }

  input[type="checkbox"] {
    @include beaut-dang-input-checkbox-styles;
  }

  input[type="checkbox"]:before {
    @include beaut-dang-input-checkbox-before-styles;
  }

  input[type="checkbox"]:after {
    @include beaut-dang-input-checkbox-after-styles;
  }

  input[type="checkbox"]:checked:after {
    left: 8.625rem;
    border-color: $ea-gray-light;
  }

  textarea {
    @include textarea-toggle-on-styles;
  }

  textarea:focus,
  textarea:active {
    background-color: $ea-white;
  }

  input[type="checkbox"]:checked + textarea {
    display: block;
    box-shadow: -1.125rem 0.75rem 1.125rem -0.313rem rgba(0, 0, 0, 0.35);
  }

  .submitBtn {
    @include button-submit;
    margin-top: 10rem;
  }
}
