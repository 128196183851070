@import "../variables.module.scss";

.owensCard {
  @include card-space;

  button {
    @include button-space;
    @include button-submit;
  }

  button:hover {
    @include button-hover;
  }

  .changeWowBtn {
    font-size: 1.5rem;
    @include button-recall-api;
  }

  video {
    @include border-box-shadow;
  }

  .contextText {
    font-variant: small-caps;
    color: $ea-red;
  }

  .wowText {
    font-size: 2rem;
    font-family: $font-amatic-sc;
  }

  img {
    width: 50%;
    display: block;
    margin: auto;
    @include border-box-shadow;
  }

  .errorMsg {
    margin-top: -0.05rem;
    margin-bottom: -2rem;
    font-size: $space-lg;
    font-family: $font-amatic-sc;
  }
}
