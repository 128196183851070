@import "../variables.module.scss";

.funnyestCard {
  @include card-space;

  button {
    @include button-space;
    @include button-submit;
  }

  button:hover {
    @include button-hover;
  }

  form {
    border: $space-border-none-all;
  }

  .funnyestForm {
    margin-left: $space-lg;
  }

  p {
    @include paragraph-basic;
  }

  input[type="checkbox"] {
    width: 7%;
    height: 1.25rem;
    cursor: pointer;
    accent-color: $ea-black;
  }

  label {
    font-size: 1.65rem;
    color: $ea-white;
    letter-spacing: 0.2rem;
    font-family: $font-rubik-distressed;
    text-shadow: 0.05rem 0.05rem $ea-gray-dark;
    cursor: pointer;
  }

  .funnyestPara {
    @include character-para-xsm;
  }

  .funnyestPara {
    text-align: left;
    font-size: xx-large;
  }

  .para1 {
    float: left;
    width: 50%;
  }

  .para2 {
    float: right;
    width: 50%;
  }

  #jamiefoxx:hover::after {
    content: url(images/jamiefoxx.png);
    zoom: 37%;
    position: absolute;
    top: 30rem;
    left: 47rem;
    @include border-box-shadow;
  }

  #whoopigoldberg:hover::after {
    content: url(images/whoopigoldberg.png);
    zoom: 37%;
    position: absolute;
    top: 37rem;
    left: 43rem;
    @include border-box-shadow;
  }

  #momsmabley:hover::after {
    content: url(images/momsmabley.jpeg);
    zoom: 37%;
    position: absolute;
    top: 40rem;
    left: 47rem;
    @include border-box-shadow;
  }

  #berniemac:hover::after {
    content: url(images/berniemac.png);
    zoom: 37%;
    position: absolute;
    top: 45rem;
    left: 47rem;
    @include border-box-shadow;
  }

  #davidmann:hover::after {
    content: url(images/davidmann.png);
    zoom: 37%;
    position: absolute;
    top: 50rem;
    left: 47rem;
    @include border-box-shadow;
  }

  #helenmartin:hover::after {
    content: url(images/helenmartin.jpg);
    zoom: 37%;
    position: absolute;
    top: 25rem;
    right: 3rem;
    @include border-box-shadow;
  }

  #eddiemurphy:hover::after {
    content: url(images/eddiemurphy.png);
    zoom: 37%;
    position: absolute;
    top: 30rem;
    right: 3rem;
    @include border-box-shadow;
  }

  #lawandapage:hover::after {
    content: url(images/lawandapage.png);
    zoom: 37%;
    position: absolute;
    top: 35rem;
    right: 3rem;
    @include border-box-shadow;
  }

  #wandasykes:hover::after {
    content: url(images/wandasykes.png);
    zoom: 37%;
    position: absolute;
    top: 40rem;
    right: 3rem;
    @include border-box-shadow;
  }

  #roberttownsend:hover::after {
    content: url(images/roberttownsend.png);
    zoom: 37%;
    position: absolute;
    top: 50rem;
    right: 3rem;
    @include border-box-shadow;
  }

  .submitBtn {
    @include button-submit;
  }
}
