@import "../variables.module.scss";

.greatestCard {
  @include card-space;

  button {
    @include button-space;
    @include button-submit;
  }

  button:hover {
    @include button-hover;
  }

  form {
    border: $space-border-none-all;
  }

  .greatestForm {
    margin-left: $space-lg;
  }

  p {
    @include paragraph-basic;
  }

  input[type="checkbox"] {
    width: 7%;
    height: 1.25rem;
    accent-color: $ea-white;
    cursor: pointer;
  }

  label {
    cursor: pointer;
    color: $ea-red;
    text-shadow: $space-xsm $space-xsm $ea-black;
  }

  .greatestPara {
    @include character-para-xsm;
  }

  .greatestPara {
    text-align: left;
    font-size: 1.5rem;
  }

  .para1 {
    float: left;
    width: 50%;
  }

  .para2 {
    float: right;
    width: 50%;
  }

  #theprincessbride:hover::after {
    content: url(images/theprincessbride.png);
    zoom: 53%;
    position: absolute;
    top: 10rem;
    left: 37rem;
    @include border-box-shadow;
  }

  #aliceinwonderland:hover::after {
    content: url(images/aliceinwonderland.png);
    zoom: 57%;
    position: absolute;
    top: 15rem;
    left: 30rem;
    @include border-box-shadow;
  }

  #legend:hover::after {
    content: url(images/legend.png);
    zoom: 57%;
    position: absolute;
    top: 17rem;
    left: 30rem;
    @include border-box-shadow;
  }

  #panslabyrinth:hover::after {
    content: url(images/panslabyrinth.png);
    zoom: 51%;
    position: absolute;
    top: 20rem;
    left: 34rem;
    @include border-box-shadow;
  }

  #theneverendingstory:hover::after {
    content: url(images/theneverendingstory.png);
    zoom: 57%;
    position: absolute;
    top: 15rem;
    left: 30rem;
    @include border-box-shadow;
  }

  #chittychittybangbang:hover::after {
    content: url(images/chittychittybangbang.png);
    zoom: 51%;
    position: absolute;
    top: 17rem;
    right: 2rem;
    @include border-box-shadow;
  }

  #jasonandtheargonauts:hover::after {
    content: url(images/jasonandtheargonauts.png);
    zoom: 57%;
    position: absolute;
    top: 20rem;
    right: 2rem;
    @include border-box-shadow;
  }

  #thewiz:hover::after {
    content: url(images/thewiz.png);
    zoom: 59%;
    position: absolute;
    top: 21rem;
    right: 2rem;
    @include border-box-shadow;
  }

  #thedarkcrystal:hover::after {
    content: url(images/thedarkcrystal.png);
    zoom: 53%;
    position: absolute;
    top: 23rem;
    right: 2rem;
    @include border-box-shadow;
  }

  #whoframedrogerrabbit:hover::after {
    content: url(images/whoframedrogerrabbit.png);
    zoom: 57%;
    position: absolute;
    top: 25rem;
    right: 2rem;
    @include border-box-shadow;
  }

  .submitBtn {
    @include button-submit;
  }
}
