@import "../variables.module.scss";

.sexiestCard {
  @include card-space;
  height: 60vh;

  button {
    @include button-space;
    @include button-submit;
  }

  button:hover {
    @include button-hover;
  }

  .fieldsetDiv {
    @include fieldset-div-basic-structure;
  }

  fieldset {
    @include fieldset-basic-structure;
    align-items: left;
  }

  .deblur {
    color: $ea-white;
    font-size: 1.45rem;
    z-index: -1;
  }

  label {
    display: inline-flex;
    position: relative;
    font-size: 1.45rem;
  }

  p.sexiestPara {
    @include p-character-para-left;
  }

  .sexiestPara {
    @include character-para-sm;
    font-size: 1.5rem;
  }

  .feminineLegend,
  .masculineLegend,
  .nonbinaryPoster {
    color: $ea-white;
    font-size: small;
    font-family: "Courier New", Courier, monospace;
  }

  .feminineLegend:hover,
  .masculineLegend:hover,
  .nonbinaryLegend:hover,
  .nonbinaryPoster:hover {
    color: $ea-red;
    text-shadow: 0.015rem 0.015rem 0.025rem $ea-black;
    transform: scale(2.25);
    transition: 1.1s ease-in;
  }

  .para1,
  .feminineLegend,
  .para2,
  .masculineLegend,
  .para3,
  .nonbinaryLegend {
    text-align: left;
    margin-left: $space-lg;
  }

  input[type="radio"] {
    width: 0.7rem;
    cursor: pointer;
    accent-color: $ea-red;
    @include icon-click-effect;
  }

  input[type="radio"]:hover {
    @include icon-click-effect;
  }

  .customIcon {
    display: inline-flex;
    position: absolute;
    top: 0.1rem;
    left: 0.05rem;
  }

  #catwoman:hover:after {
    content: url(images/catwoman.png);
    zoom: 65%;
    position: absolute;
    top: $space-sm;
    left: $space-sm;
  }

  #shera:hover:after {
    content: url(images/she-ra.png);
    zoom: 65%;
    @include img-basic-position;
  }

  #wonderwoman:hover:after {
    content: url(images/wonder-woman.png);
    zoom: 65%;
    @include img-basic-position;
  }

  #batman:hover:after {
    content: url(images/batman.png);
    zoom: 40%;
    position: absolute;
    top: $space-md;
    left: 0;
  }

  #blackpanther:hover:after {
    content: url(images/black-panther.png);
    zoom: 43%;
    position: absolute;
    top: $space-sm;
  }

  #spiderman:hover:after {
    content: url(images/spiderman.png);
    zoom: 37%;
    @include img-basic-position;
  }

  #kidquick:hover:after {
    content: url(images/kid-quick.png);
    zoom: 67%;
    position: absolute;
    top: $space-sm;
    right: $space-sm;
  }
}
