@import "../variables.module.scss";

.prettiestCard {
  background-color: rgba(255, 255, 0, 0.2);
  @include card-space;

  button {
    @include button-space;
    @include button-submit;
  }

  button:hover {
    @include button-hover;
  }

  fieldset {
    @include fieldset-basic-structure;
    align-items: center;
  }

  .whatshot {
    color: aqua;
    font-size: 1.5rem;
    z-index: -1;
  }

  label {
    display: inline-flex;
    position: relative;
    color: aqua;
    font-size: 1.75rem;
    margin-right: $space-sm;
  }

  input[type="radio"] {
    width: 0.5rem;
    cursor: pointer;
    accent-color: aqua;
    @include icon-click-effect;
  }

  input[type="radio"]:hover {
    @include icon-click-effect;
  }

  .customIcons {
    display: inline-flex;
    position: absolute;
    top: -0.25rem;
    left: $space-xsm;
  }

  p.prettiestPara {
    @include p-character-para-center;
  }

  .prettiestPara {
    @include character-para-md;
  }

  .pretties {
    @include character-subheads;
    color: yellow;
    font-size: xx-small;
    display: block;
    margin-left: 2.5rem;
  }

  .pretties:hover {
    font-size: x-small;
    color: yellow;
    @include characters-hover;

    .subTitleLeadIn {
      font-size: x-small;
      display: block;
    }

    i {
      color: orangered;
    }
  }

  .submitBtn {
    @include button-submit;
  }
}
