@import "../variables.module.scss";

.ronsCard {
  @include card-space;
  background-color: rgba(254, 1, 154, 0.3);

  button {
    cursor: pointer;
    font-size: 2.5rem;
    text-transform: uppercase;
    font-family: $font-rubik-dirt;
    text-shadow: $space-xsm $space-xsm $space-xsm $ea-black;
    @include button-space;
  }

  Button {
    color: #ff1694;
    @include button-x-custom-1;
  }

  .changeQuoteBtn {
    font-size: 1.45rem;
    @include button-recall-api;
  }

  .changeQuoteBtn:hover {
    transform: scale(1.25);
  }

  .manlyModeBtn {
    font-size: 1.25rem;
    margin-bottom: 1.25rem;
  }

  p {
    color: deeppink;
    text-shadow: $ea-black $space-xsm $space-xsm;
    font-size: 2.25rem;
    line-height: 3rem;
    font-family: "Aggressive";

    text-align: left;
    text-justify: inter-word;
    margin-left: 7rem;
    margin-right: 7rem;
  }
}

.manly .ronsCard {
  background-color: rgba(0, 0, 255, 0.3);
}
.manly Button {
  color: deepskyblue;
}
.manly p {
  color: deepskyblue;
}

.lineUpEffect {
  @include line-up-effect;
}
@keyframes anim-lineUp {
  0% {
    opacity: 0;
    transform: translateY(80%);
  }
  20% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
