@import "../variables.module.scss";

.marvelCard {
  height: 77vh;
  background-image: radial-gradient(orangered 50%, transparent 50%);
  background-position: 0 0 3.125rem 3.125rem;
  background-size: 1.05rem 1.05rem;

  background-color: deeppink;
  @include card-space;

  button {
    @include button-space;
    @include button-submit;
  }

  Button {
    color: #0bda51;
    @include button-x-custom-1;
  }

  button:hover {
    @include button-hover;
  }

  form {
    margin: 0 auto;
    width: 50%;
  }

  input[type="text"] {
    border-bottom: 0.1rem dotted #0bda51;
    width: 60%;
    margin-top: 3.15rem;
    outline: none;
    padding-left: 0.5rem;
    font-size: medium;
  }

  input {
    color: $ea-white;
  }

  input[type="text"]::placeholder {
    color: $ea-white;
    @include input-number-and-text-format;
    padding-left: 0.25rem;
  }

  .searchBtn {
    font-size: 2rem;
    margin-top: 0.4rem;
  }

  .creatorName {
    color: deeppink;
    font-size: 3.15rem;
    text-shadow: 0.1rem 0.015rem $ea-black;
  }

  .creatorComicCoverOrPortraitImg {
    width: 17%;
    position: absolute;
    right: 2.15rem;
    top: 2rem;

    border: 0.25rem solid greenyellow;

    z-index: 1;
  }

  a.linkToCreatorCreations {
    color: darkcyan;
    font-family: $font-flow-block;
    text-shadow: $space-xsm 0.1rem $ea-black;

    position: absolute;
    top: 22rem;
    left: 11rem;

    text-align: center;
    -webkit-animation: glow 1s ease-in-out infinite alternate;
    -moz-animation: glow 1s ease-in-out infinite alternate;
    animation: glow 1s ease-in-out infinite alternate;
  }

  a.linkToCreatorCreations:hover {
    transform: scale(1.1);
    transition: ease-in-out 1.5s;

    font-family: Notable;

    position: absolute;
    top: 21rem;
    left: 11rem;
  }

  .subtitles {
    font-variant: small-caps;
  }

  .comics {
    color: #0bda51;
    font-size: x-small;
    text-align: left;
    width: $space-lg;
    font-family: $font-flow-block;
    text-shadow: $space-xsm 0.05rem $ea-black;

    position: absolute;
    top: 22rem;
    right: 20rem;

    text-align: center;
    -webkit-animation: glow 1s ease-in-out infinite alternate;
    -moz-animation: glow 1s ease-in-out infinite alternate;
    animation: glow 1s ease-in-out infinite alternate;
  }

  .comics:hover {
    width: 37%;
    font-size: medium;
    font-family: $font-notable;

    position: absolute;
    right: 10rem;
    top: 25rem;
  }

  .events {
    color: darkturquoise;
    font-size: x-small;
    text-align: left;
    width: 5%;
    font-family: $font-flow-block;
    text-shadow: $space-xsm 0.05rem $ea-black;

    position: absolute;
    top: 24rem;
    right: 2rem;

    text-align: center;
    -webkit-animation: glow 1s ease-in-out infinite alternate;
    -moz-animation: glow 1s ease-in-out infinite alternate;
    animation: glow 1s ease-in-out infinite alternate;
  }

  .events:hover {
    width: 25%;
    font-size: medium;
    font-family: $font-notable;

    position: absolute;
    top: 24rem;
    right: 3rem;
  }

  .series {
    color: dodgerblue;
    font-size: xx-small;
    text-align: left;
    width: 7%;
    font-family: $font-flow-block;
    text-shadow: $space-xsm 0.05rem $ea-black;

    position: absolute;
    top: 22rem;
    left: 20rem;

    text-align: center;
    -webkit-animation: glow 1s ease-in-out infinite alternate;
    -moz-animation: glow 1s ease-in-out infinite alternate;
    animation: glow 1s ease-in-out infinite alternate;
  }

  .series:hover {
    width: 40%;
    font-size: medium;
    font-family: $font-notable;

    position: absolute;
    top: 23rem;
    left: 19rem;
  }

  .storyFromStories {
    color: yellow;
    font-size: x-small;
    text-align: left;
    width: 6rem;
    font-family: $font-flow-block;
    text-shadow: $space-xsm 0.05rem $ea-black;

    position: absolute;
    top: 19rem;
    right: $space-lg;

    text-align: center;
    -webkit-animation: glow 1s ease-in-out infinite alternate;
    -moz-animation: glow 1s ease-in-out infinite alternate;
    animation: glow 1s ease-in-out infinite alternate;
  }

  .storyFromStories:hover {
    width: 25%;
    font-size: medium;
    font-family: $font-notable;
    text-align: left;
    text-shadow: $space-xsm 0.05rem $ea-black;

    position: absolute;
    top: $space-lg;
    left: 4.5rem;
  }

  .kapow {
    width: 5.7rem;
    position: absolute;
    top: 19rem;
    left: 3rem;
  }

  .pow {
    width: 4.7rem;
    position: absolute;
    top: 21rem;
    right: 14rem;
  }

  .skyline {
    background-image: url("images/skyline.png"),
      linear-gradient(
        to bottom,
        deeppink 0%,
        orangered 30%,
        orange 60%,
        yellow 80%,
        $ea-green 100%
      );
    background-size: cover, auto;
    background-position: bottom;
    background-repeat: repeat-x;
    width: 89vw;
    margin-left: -0.65rem;
    margin-bottom: -$space-sm;

    z-index: -100;
  }

  .totalResults {
    color: $ea-white;
    font-size: 0.5rem;
    font-family: $font-notable;
    margin-top: -3rem;
  }

  .marvelApiLinkPara {
    color: #0bda51;
    text-shadow: 0.015rem 0.015rem $ea-black;
    font-size: x-small;
    position: absolute;
    top: 10rem;
    left: -5rem;
    rotate: calc(-90deg);
  }

  .errorText {
    color: $ea-black;
    text-shadow: 0.1rem 0.1rem green;
    font-size: $space-lg;
    font-family: Notable;
    font-variant: small-caps;
    letter-spacing: $space-xsm;
  }
}
