@font-face {
  font-family: "Aggressive";
  src: local("Aggressive"),
    url("./fonts/Agressive-OVVzO.ttf") format("truetype");
}

html {
  scroll-behavior: smooth;
  user-select: none;
}
body {
  margin: 0;
  background-color: white;
}
div.logoAndNavDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}
nav {
  margin-top: 3.75rem;
}
#epicapislogo {
  width: 17rem;
  height: 100%;
  margin-top: 3rem;
}

ul > li {
  list-style: none;
}
a {
  text-decoration: none;

  color: greenyellow;
  text-shadow: black 0.025rem 0.025rem;
  font-family: "Courier New", Courier, monospace;
}
.summaryPdf,
.dataFlowPdf {
  font-size: 1.25rem;
  letter-spacing: 0.15rem;
}
.dataFlowPdf {
  margin-bottom: 0.45rem;
}
.listWordFont {
  font-family: cursive;
}
.listWordColor {
  color: #545454;
  font-size: 1.25rem;
}
a.listWordColor {
  color: #5ce1e6;
}
.myName {
  font-size: 0.7rem;
}
.infoIcon {
  width: 5%;
  vertical-align: super;
}
.dotDevSiteText {
  margin-bottom: -0.5rem;
}
.epicapisCopyrightText {
  letter-spacing: 0.15rem;
}
.apisOfEpicapis {
  font-family: Arial, Helvetica, sans-serif;
  font-size: small;
}

// start of persisted content header styles
.epic-mode {
  background-color: yellowgreen;
}
.epic-mode .listWordColor {
  color: #fff;
  font-size: 1.25rem;
}
.epic-mode #epicapislogo {
  filter: drop-shadow(0.15rem 0.15rem 0.15rem #222);
  width: 17rem;
}
.epic-mode a,
.epic-mode .apisOfEpicapis {
  color: black;
}
.epic-mode .selectionsSubmitBtn {
  color: black !important;
}
.epic-mode .myName {
  font-size: 0.7rem;
}
button.epicModeBtn {
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 1rem;
  background-color: yellowgreen;
  text-shadow: black 0.025rem 0.025rem;

  width: 7rem;
  padding: 0.5rem 1rem;

  position: absolute;
  top: 1.5rem;
  left: 1.5rem;
}
.epic-mode button.epicModeBtn {
  color: yellowgreen;
  border: none;
  border-radius: 1rem;
  background-color: #fff;
  text-shadow: black 0.025rem 0.025rem;
}
.epic-mode input[type="text"]::placeholder,
.epic-mode .delete {
  color: #fff;
}
.epic-mode input[type="text"] {
  border-bottom: 0.05rem solid #fff;
}
.epic-mode .epicApisH1,
.epic-mode .visitCountDiv {
  color: black;
}
.epic-mode input[type="reset"],
.epic-mode #submitUserName {
  color: #fff;
}

#greetForm,
.epicApisH1 {
  color: #545454;
  font-family: Bebas Neue;
  font-size: 1.75rem;

  position: absolute;
  left: 1.5rem;
  top: 3rem;
}
input[type="text"] {
  width: 13.5rem;
  background-color: transparent;
  border: none;
  border-bottom: 0.05rem solid #c8c4bd;
  margin-top: 2rem;
}
input[type="text"]::placeholder {
  color: #c8c4bd;
  font-size: x-small;
}
#submitUserName {
  color: #c8c4bd;
  font-size: x-small;
  border: none;
  cursor: pointer;

  background-color: transparent;
}
button#reset {
  position: absolute;
  top: 13rem;
  left: 6rem;
}
.delete {
  color: #c8c4bd;
  font-size: 0.5rem;
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 3.15rem;
  cursor: pointer;
}
input[type="reset"] {
  border: none;
  color: #c8c4bd;
  text-shadow: 0.015rem 0.015rem black;
  font-size: 0.5rem;
  background-color: transparent;
}

.visitCountDiv {
  display: inline;
  font-size: 0.75rem;
  position: absolute;
  top: 17rem;
  left: 1.5rem;

  color: #545454;
  letter-spacing: 0.05rem;
  text-shadow: 0.025rem 0.025rem black;
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
}
.visitCountPara {
  font-family: cursive;
  font-size: 1.25rem;
  position: absolute;
  top: 15.15rem;
  left: 5.5rem;
}
#reset {
  color: white;
  width: 2.75rem;
  border: none;
  cursor: pointer;
  margin-top: 4.15rem;
  margin-left: 1.75rem;
  padding-top: 0.05rem;
  padding-bottom: 0.2rem;
  padding-left: 0.25rem;
  border-radius: 1rem;
  font-size: xx-small;
  text-shadow: 0.05rem 0.05rem black;
  background-color: #c9e265;
}
// end of persisted content header styles

.agify,
.chuck,
.cocktails,
.foaas,
.marvel,
.owen,
.pokemon,
.starwars,
.swanson,
.trump,
.semanticFillerImg {
  width: 10rem;
  height: auto;
  margin-left: 0.3rem;
}
.agify:hover,
.chuck:hover,
.cocktails:hover,
.foaas:hover,
.marvel:hover,
.owen:hover,
.pokemon:hover,
.starwars:hover,
.swanson:hover,
.trump:hover,
.semanticFillerImg:hover {
  transform: scale(1.05);
  transition: 0.75s;
  cursor: pointer;
}

.selectionsForm {
  display: flex;
  flex-direction: column;
  align-items: center;
}
input[type="email"]::placeholder {
  color: #cae265;
  font-size: x-small;
  padding: 0.25rem 0.5rem;
}
input#email.selectionsInput {
  margin-top: 1rem;
}
.selectionsInput {
  width: 50%;
  border-color: #545454;
  border-radius: 0.25rem;
  padding: 0.15rem;
}
Button.selectionsSubmitBtn {
  color: #c9e265;
  font-size: large;
}

/* masonry code */
/* Do Not Need either display below (webkit; flexbox) if autoprefixing */
.my-masonry-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: auto;
}
/* padding-left is the gutter size */
.my-masonry-grid_column {
  background-clip: padding-box;
}
.my-masonry-grid_column > div {
  margin-bottom: 1rem;
}
.apiImgDiv {
  text-align: center;
}

/* blur background on modal open */
.MuiBackdrop-root {
  backdrop-filter: blur(7px);
}

/* media queries per smallest to largest creator recommended screen sizes */
.my-masonry-grid {
  margin: 0 auto;
}

@media screen and (min-width: 670px) {
  .my-masonry-grid {
    max-width: 670px;
  }
}

@media screen and (min-width: 1000px) {
  .my-masonry-grid {
    max-width: 1000px;
  }
}

.mediaQueryMsg {
  color: greenyellow;
  font-size: 3rem;
  text-align: center;
  font-family: Rubik Glitch;
  text-shadow: 0.15rem 0.15rem #545454;
  background-color: #545454;

  padding: 25% 0;
}

.mediaQueryText {
  letter-spacing: 0.5rem;
}

.mediaQueryMsgToo {
  font-size: 3rem;
  text-align: center;
  font-family: Bebas Neue;
  background-color: greenyellow;

  padding: 25% 0;
}

.optimizedText {
  font-family: Rubik Glitch;
}
