@import "../variables.module.scss";

.sayCard {
  @include card-space;

  button {
    @include button-space;
    @include button-submit;
  }

  button:hover {
    @include button-hover;
  }

  fieldset {
    @include fieldset-basic-structure;
    align-items: center;
  }

  label {
    cursor: pointer;
  }

  select {
    color: #c8c4bd;
    text-align: center;

    width: 37%;
    padding: 0.75rem;
    cursor: pointer;

    appearance: none;
    -moz-appearance: none;
    text-indent: 0.063rem;
    text-overflow: "";
  }

  .phraseToShowText {
    color: $ea-white;
    font-family: $font-bebas-neue;
    font-size: 2rem;
    text-shadow: 0.05rem 0.05rem blue;
  }

  .submitBtn {
    @include button-submit;
  }
}
