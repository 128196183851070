@import "../variables.module.scss";

.foaasCard {
  background-color: rgba(255, 255, 255, 0.1);
  @include card-space;

  button {
    @include button-space;
    @include button-submit;
  }

  Button {
    font-size: $space-xmd;
  }

  button:hover {
    @include button-hover;
  }

  .changeFoaasBtn {
    font-size: 1.45rem;
    @include button-recall-api;
  }

  p {
    color: $ea-white;
    font-size: small;
    color: $ea-red;
    font-family: $font-bebas-neue;
    text-shadow: 0.05rem 0.05rem $ea-black;

    position: absolute;
    top: 2.5rem;
    right: 6.5rem;
  }

  h1 {
    color: $ea-white;
    font-size: 3.05rem;
    font-family: $font-rubik-glitch;
    text-shadow: 0.1rem 0.1rem $ea-black;

    animation: glitch 1s linear infinite;
  }

  @keyframes glitch {
    2%,
    64% {
      transform: translate(0.125rem, 0) skew(0deg);
    }
    4%,
    60% {
      transform: translate(-0.125rem, 0) skew(0deg);
    }
    62% {
      transform: translate(0, 0) skew(5deg);
    }
  }

  h1:before,
  h1:after {
    content: attr(title);
    position: absolute;
    left: 0;
  }

  h1:before {
    animation: glitchTop 1s linear infinite;
    clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
  }

  @keyframes glitchTop {
    2%,
    64% {
      transform: translate(0.125rem, -0.125rem);
    }
    4%,
    60% {
      transform: translate(-0.125rem, 0.125rem);
    }
    62% {
      transform: translate(0.813rem, -0.063rem) skew(-13deg);
    }
  }

  h1:after {
    animation: glitchBotom 1.5s linear infinite;
    clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
    -webkit-clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
  }

  @keyframes glitchBotom {
    2%,
    64% {
      transform: translate(-0.125rem, 0);
    }
    4%,
    60% {
      transform: translate(-0.125rem, 0);
    }
    62% {
      transform: translate(-1.375rem, 0.313rem) skew(21deg);
    }
  }
}
