@import "../variables.module.scss";

.cocktailsCard {
  background-color: rgba(0, 255, 0, 0.1);
  @include card-space;

  button {
    @include button-space;
    @include button-submit;
  }

  Button {
    @include button-x-custom-2;
  }

  button:hover {
    @include button-hover;
  }

  .refreshDrinkBtn {
    color: greenyellow;
    font-size: 1.25rem;
    margin: 0 auto;
    @include button-recall-api;
  }

  .refreshDrinkBtn:hover {
    transform: scale(1.75);
  }

  img {
    max-width: 10rem;
    max-height: 10rem;
    margin-left: 1.75rem;
    margin-right: -$space-sm;
    margin-bottom: 1.75rem;
    @include border-box-shadow;
  }

  .detailsDiv {
    width: 99%;
    @include section-centered;
  }

  h2 {
    font-size: $space-md;
    padding-left: 3rem;
  }

  h3 {
    font-size: 0.65rem;
    text-align: left;
    margin-left: 1.25rem;
    margin-top: 2.025rem;
    text-shadow: $space-xsm $space-xsm $space-xsm $ea-black;

    animation: glow 1.5s ease-in-out infinite;
  }

  span#instructions {
    overflow: hidden;
    line-height: 1.25rem;
    max-height: 7rem;
    -webkit-box-orient: vertical;
    display: block;
    display: -webkit-box;
    overflow: scroll;
    text-overflow: ellipsis;
    -webkit-line-clamp: 7;
  }

  .detailHeaders {
    color: $ea-red;
    font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;

    @keyframes glow {
      from {
        text-shadow: 0 0 1.25rem greenyellow;
      }
      to {
        text-shadow: 0 0 1.875rem greenyellow, 0 0 0.625rem greenyellow;
      }
    }
  }

  h3:hover {
    font-size: 1.75rem;
  }

  .instructions {
    width: 7rem;
  }

  div h3.instructions:hover {
    font-size: 1.25rem;
    width: 17%;
  }
}
