@import "../variables.module.scss";

.bestCard {
  background-color: rgba(255, 0, 0, 0.3);
  @include card-space;

  button {
    @include button-space;
    @include button-submit;
  }

  button:hover {
    @include button-hover;
  }

  fieldset {
    @include fieldset-basic-structure;
    align-items: center;
  }

  input[type="radio"] {
    width: 5%;
    height: 0.95rem;
    cursor: pointer;
    accent-color: $ea-gray-dark;
  }

  label {
    cursor: pointer;
  }

  p.bestPara {
    @include p-character-para-center;
  }

  .bestPara {
    @include character-para-md;
  }

  .besties {
    @include character-subheads;
    text-shadow: 0.1rem 0.1rem $ea-black;
  }

  .besties:hover {
    @include characters-hover;
    color: $ea-red;
  }

  .parens {
    color: $ea-red;
    font-family: Arial, Helvetica, sans-serif;
  }

  .dashIshText {
    color: $ea-gray-light;
    text-shadow: 0.25rem 0.25rem black;
  }

  .submitBtn {
    @include button-submit;
  }
}
