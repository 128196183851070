// ANCHOR STYLE SET
@mixin a-style {
  text-decoration: none;

  color: greenyellow;
  text-shadow: black 0.025rem 0.025rem;
  font-family: "Courier New", Courier, monospace;
}

// AUTHOR STYLE SET
@mixin author-space {
  font-size: 0.64rem;
  display: block;
}

// BORDER REMOVE BORDER STYLE SET
@mixin border-none {
  border-top: 0;
  border-right: 0;
  border-bottom: 0;
}

@mixin border-box-shadow {
  border: $space-xsm $ea-red solid;
  box-shadow: 0 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.2),
    0 0.375rem 1.25rem 0 rgba(0, 0, 0, 0.19);
}

@mixin no-border-box-shadow {
  box-shadow: 0 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.2),
    0 0.375rem 1.25rem 0 rgba(0, 0, 0, 0.19);
}

$space-input-border-bottom: 0.2rem solid #fff;

// BUTTON STYLE SET
@mixin button-submit {
  color: $ea-red;
  font-size: 2.5rem;
  text-transform: uppercase;
  font-family: $font-rubik-dirt;
  text-shadow: 0.15rem 0.15rem 0.15rem $ea-black;
}

@mixin button-submit-input-inline {
  color: $ea-red;
  font-size: 2.25rem;
  margin: 1% 25%;
  text-transform: uppercase;
  font-family: $font-rubik-dirt;
  text-shadow: 0.15rem 0.15rem 0.15rem $ea-black;
}

@mixin button-search-submit-block-display {
  display: block;
  margin: 0% auto;
}

@mixin button-space {
  margin: 1.5rem 0;
  border: none;
  background-color: transparent;
}

@mixin button-hover {
  cursor: pointer;
  transform: scale(1.25);
}

@mixin button-x-custom-1 {
  // circle
  font-size: $space-xmd;
  margin-bottom: -1.5rem;
}

@mixin button-x-custom-2 {
  // skull
  font-size: 6.5rem;
  margin-bottom: -1.5rem;
}

@mixin button-x-custom-3 {
  // box
  font-size: $space-md;
  margin-bottom: -1.5rem;
}

@mixin button-recall-api {
  position: absolute;
  top: 3rem;
  right: 5.5rem;
}

// CARD
@mixin card-space {
  text-align: center;
  padding-bottom: $space-xmd;
}

// CHARACTERS HOVER STYLE SET
@mixin characters-hover {
  font-size: 1.25rem;
}

@mixin characters-red-text-hover {
  color: $ea-red;
  @include characters-hover;
}

// COLOR
$ea-black: black;
$ea-blue: #5ce1e6;
$ea-red: crimson;
$ea-gray-dark: #545454;
$ea-gray-light: #c8c4bd;
$ea-green: #c9e265;
$ea-white: #fff;
$ea-filler-black-background: rgba(26, 25, 30, 0.7);

// FIELDSET STYLE SET
@mixin fieldset-div-basic-structure {
  display: flex;
  align-content: center;
  justify-content: center;
  padding-right: 3.25rem;
}

@mixin fieldset-basic-structure {
  border: $space-border-none-all;
  flex-direction: column;
}

// FONT
$font-abril-fatface: Abril Fatface;
$font-amatic-sc: Amatic SC;
$font-bangers: Bangers;
$font-bebas-neue: Bebas Neue;
$font-flow-block: Flow Block;
$font-luckiest-guy: Luckiest Guy;
$font-notable: Notable;
$font-pathway-gothic-one: Pathway Gothic One;
$font-rubik-dirt: Rubik Dirt;
$font-rubik-distressed: Rubik Distressed;
$font-rubik-glitch: Rubik Glitch;

@mixin label-color-shadow {
  color: #fff;
  text-shadow: 0.025rem 0.025rem 0.025rem darkslategray;
}

@mixin text-uppercase {
  text-transform: uppercase;
  font-size: 5rem;
}

// IMAGE STYLE SET
@mixin img-basic-structure {
  float: left;
  top: 50%;
  @include no-border-box-shadow;
}

@mixin img-basic-structure-hover {
  transform: scale((1.03));
  background-color: $ea-green;
}

@mixin img-basic-position {
  position: absolute;
  top: 0;
}

// ICON (CUSTOM) STYLE SET
@mixin icon-click-effect {
  transform: scale(1.2);
  box-shadow: 50% 50% 50% rgba(0, 0, 0, 0.3);
}

// INPUT STYLE SET
@mixin input-basic-format {
  width: 25%;
  padding: 0.5rem;
  border-radius: 0.25rem;
  border-color: transparent;

  caret-color: $ea-red;

  text-transform: lowercase;
}

@mixin input-decade {
  width: 5%;
  cursor: default none;
  text-align: center;
  margin-right: 0.75rem;
}

@mixin input-text-placeholder {
  color: $ea-gray-light;
  font-size: x-small;
  padding-left: $space-xsm;
}

@mixin input-text-selection {
  background: $ea-white;
  color: #c9e265;
}

@mixin input-number-and-text-format {
  font-size: medium;
  height: $space-sm;
  margin-top: 0.5rem;
}

@mixin input-combo-number-text-format {
  border-bottom: $space-input-border-bottom;
  color: $ea-white;
  font-size: small;
  margin-top: -0.05rem;
}

@mixin beaut-dang-input-checkbox-styles {
  appearance: none;
  background-color: #222127;
  height: $space-lg;
  width: 12.5rem;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  box-shadow: 1.125rem 0.75rem 1.125rem -0.313rem rgba(0, 0, 0, 0.35);
  border-radius: 2.5rem;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
}

@mixin beaut-dang-input-checkbox-before-styles {
  position: absolute;
  content: "";
  height: 0.625rem;
  width: 0.625rem;
  background-color: #0ba041;
  border-radius: 50%;
  top: 1.25rem;
  left: 1.688rem;
  box-shadow: 0 2.063rem 0 0 #0ba041, 8.438rem 2.063rem 0 0 $ea-gray-light,
    8.438rem 0 0 0 $ea-gray-light;
}

@mixin beaut-dang-input-checkbox-after-styles {
  position: absolute;
  content: "";
  height: 1.438rem;
  width: 0.625rem;
  border: 0.25rem solid #0ba041;
  border-left: none;
  border-radius: 0 1.438rem 1.438rem 0;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 3.125rem;
  transition: 0.3s;
}

// LIST STYLE SET
@mixin list-word {
  font-family: cursive;
  color: #545454;
}

@mixin ul-space {
  margin-top: -2.5rem;
  margin-left: -1%;
}

@mixin ul-li {
  list-style: none;
}

// LOGO STYLE SET
@mixin logo-space {
  width: 11rem;
  height: 100%;
  margin-left: 1.5%;
}

// MASONRY STYLE SET
@mixin masonry-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: auto;
}

@mixin masonry-grid-column {
  background-clip: padding-box;
}

// PARAGRAPH STYLE SET
@mixin paragraph-basic {
  flex-direction: column;
  align-items: center;
}

@mixin p-character-para-center {
  display: flex;
  justify-content: center;
}

@mixin p-character-para-left {
  display: flex;
  justify-content: left;
}

@mixin p-character-para-right {
  display: flex;
  justify-content: right;
}

@mixin character-para-xsm {
  text-align: left;
  font-size: 1.5rem;
  font-family: $font-rubik-dirt;
}

@mixin character-para-sm {
  text-align: left;
  font-size: 1.75rem;
  font-family: $font-rubik-dirt;
}

@mixin character-para-md {
  text-align: left;
  font-size: 2rem;
  font-family: $font-rubik-dirt;
}

@mixin character-subheads {
  color: $ea-gray-light;
  font-size: x-small;
}

@mixin line-up-effect {
  animation: 2.5s anim-lineUp ease-out infinite;
}

@mixin beaut-dang-para-styles {
  width: 30%;
  text-align: justify;
  position: absolute;
  top: 8rem;
  left: 3rem;
}

// SECTION STYLE SET
@mixin section-basic-format {
  display: flex;
  justify-content: space-evenly;
  word-spacing: 0.25rem;
}

@mixin section-centered {
  display: flex;
  align-content: center;
  justify-content: center;
}

// SELECT STYLE SET
@mixin select-option {
  background-color: $ea-transparent;
  border-radius: $space-border-radius-sm;
  width: 27rem;
  padding: $space-sm;

  color: $ea-white;
  font-size: 2.25rem;
  font-family: "Rubik Dirt";
}

// SEMANTIC FILLER STYLE SET
@mixin sem-fill-space {
  width: 10rem;
  height: auto;
  margin-left: 0.3rem;
}

@mixin sem-fill-hover {
  transform: scale(1.05);
}

// SIZE/SPACE
$space-xsm: 0.15rem;
$space-sm: 1rem;
$space-md: 3.5rem;
$space-xmd: 4rem;
$space-lg: 5rem;

$space-border-none-all: 0;
$space-border-radius-sm: 0.5rem;

// TEXTAREA STYLES
@mixin textarea-toggle-on-styles {
  width: 34%;
  height: 100%;
  padding: 3rem;
  resize: none;
  box-sizing: border-box;

  position: absolute;
  top: 0;
  right: 0.1rem;
  color: $ea-gray-light;
  background-color: rgba(26, 25, 30, 0.9);
}
